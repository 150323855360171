<template>
  <div style="background-color: blue">efefe</div>
  <div style="position: absolute; width: 70px; height: 70px">
    <Atom div-id="el1" id="8" symbol="O" category="1" class="w-full" />
  </div>
</template>

<script>
import Atom from '@/components/Atom'
export default {
  name: 'H2',

  components: {
    Atom,
  },
}
</script>
